'use strict';
(function () {

    class ClientComponent {
        constructor($scope, $state, usSpinnerService, Util, $http, Auth, Organization, Modal, $uibModal) {
            this.Organization = Organization;
            this.$http = $http;
            this.Modal = Modal;
            this.$uibModal = $uibModal;
            this.organizations = [];

            Auth.isLoggedInAsync((loggedIn) => {
                if (loggedIn) {
                    this.init();
                    this.currentUser = Auth.getCurrentUser();
                } else {
                    $state.go('login');
                }
            });
          let siteUrl = window.location.href;
          this.exportFeatureEnable = siteUrl.includes('devwindmanager') || siteUrl.includes('localhost') ? true : false;
        }

        init() {
            this.Organization.get().$promise
            .then(response=> {
                if (response.data && response.data.length) {
                  this.organizations = response.data;
                }
            })
            .catch(err=> {
                console.log(err);
            });
        }

        startSpin(spinner) {
            this.usSpinnerService.spin(spinner);
        }

        stopSpin(spinner) {
            this.usSpinnerService.stop(spinner);
        }

        editOrg(organization) {
            var self = this;
            var modalInstance = this.$uibModal.open({
              size: 'md',
              templateUrl: 'components/modal/organization/addEditOrganizationModal/addEditOrganizationModal.html',
              controller: 'AddEditOrganizationModalCtrl',
              controllerAs: 'aEOrganization',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                options: {
                  title: 'Edit Client',
                  organization: organization
                }
              }
            });
            modalInstance.result.then(function(organization) {
              var targetOrganization = _.find(self.organizations, { _id: organization.data._id });
              if(targetOrganization) {
                targetOrganization = _.merge(targetOrganization, organization.data);
              } else {
                self.Organization.get().$promise
                  .then(response=> {
                    if (response.data && response.data.length) {
                      self.organizations = response.data;
                    }
                  });
              }
            });
          }

        openAddOrganizationModal() {
            var self = this;
            var modalInstance = this.$uibModal.open({
              size: 'md',
              templateUrl: 'components/modal/organization/addEditOrganizationModal/addEditOrganizationModal.html',
              controller: 'AddEditOrganizationModalCtrl',
              controllerAs: 'aEOrganization',
              backdrop: 'static',
              keyboard: false,
              resolve: {
                options: {
                  title: 'Add New Client'
                }
              }
            });
            modalInstance.result.then(function(organization) {
              self.organizations.unshift(new self.Organization(organization.data));
            });
          }

          exportClient() {
            this.$http({
              method: 'GET',
              url: "/api/v1/orgs/export/organization",
              // This is important! Tell it to expect an arraybuffer
              responseType: "arraybuffer"
            })
            .success((data, status, headers, config) => {
              console.log('data', data);
              const d = moment().format('YYYYMMD/hh:mm/')
              // use the saveAs library to save the buffer as a blob on the user's machine. Use the correct MIME type!
              saveAs(new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), d +"Clients.xlsx");
            })
            .error((data, status, headers, config) => {
              console.log(data);
            });
          }
    }

    angular.module('windmanagerApp')
        .component('clients', {
            templateUrl: 'app/clients/clients.html',
            controller: ClientComponent,
            controllerAs: 'cc'
        });

})();
